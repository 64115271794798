<template>
  <div class="create-trouble">
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="$router.back()"
    ></van-nav-bar>
    <van-form :show-error-message="false" label-width="22vw" @submit="onSubmit">
      <van-field
        v-model="info.name"
        :rules="[{ required: true, message: '请填写隐患名称' }]"
        label="隐患名称"
        name="name"
        input-align="right"
        placeholder="请输入内容"
        maxlength="100"
        required
        @input="handleInput('name')"
      />
      <van-field
        v-model="info.poiName"
        :rules="[{ message: '请填写部位名称' }]"
        label="隐患部位"
        name="poiName"
        input-align="right"
        placeholder="请输入内容"
        readonly
        @click="facilityVisible = true"
      />
      <van-field
        :rules="[{ required: true, message: '请填写隐患级别' }]"
        :value="info.troubleLevelName"
        clickable
        input-align="right"
        label="隐患级别"
        name="troubleLevel"
        placeholder="请选择"
        readonly
        required
        @click="handlerTypesClick($event, 'troubleLevel')"
      />
      <van-field
        :rules="[{ required: true, message: '请填写隐患类别' }]"
        :value="info.categoryName"
        clickable
        input-align="right"
        label="隐患类别"
        name="categoryId"
        placeholder="请选择"
        readonly
        required
        @click="handlerTypesClick($event, 'categoryId')"
      />
      <van-field
        v-if="showContractor"
        :rules="[{ required: true, message: '请填写隐患名称' }]"
        :value="info.contractorName"
        clickable
        label="承包商"
        input-align="right"
        name="contractorId"
        placeholder="请选择"
        readonly
        required
        @click="handlerTypesClick($event, 'contractorId')"
      />
      <!--      <van-field-->
      <!--        :value="info.planName"-->
      <!--        @click="handlerTypesClick($event, 'planId')"-->
      <!--        clickable-->
      <!--        label="所属计划"-->
      <!--        input-align="right"-->
      <!--        name="planId"-->
      <!--        placeholder="请选择"-->
      <!--        readonly-->
      <!--      />-->
      <van-field
        :rules="[{ required: true, message: '请选择所属部门' }]"
        :value="info.departmentName"
        clickable
        label="所属部门"
        input-align="right"
        name="department"
        placeholder="请选择"
        readonly
        required
        @click="departmentVisible = true"
      />
      <van-field
        :rules="[{ required: true }]"
        label="发现方式"
        name="radio"
        required
        input-align="right"
      >
        <template #input>
          <van-radio-group v-model="info.discoveryId" direction="horizontal">
            <van-radio
              v-for="item in discoveryData"
              :key="item.value"
              :name="item.value"
              >{{ item.label }}</van-radio
            >
            <!-- :disabled="+item.value === 1" -->
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="info.description"
        autosize
        input-align="right"
        label="备注"
        maxlength="500"
        placeholder="请输入内容"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('description')"
      />
      <van-field
        v-model="info.gist"
        autosize
        input-align="right"
        label="检查依据"
        maxlength="500"
        name="gist"
        placeholder="请输入内容"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('gist')"
      />
      <van-field
        v-model="info.suggest"
        autosize
        label="控制措施"
        input-align="right"
        maxlength="500"
        name="suggest"
        placeholder="请输入内容"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('suggest')"
      />
      <van-field
        class="details-page__last-from-item"
        label="现场照片"
        input-align="right"
        name="uploader"
      >
        <template #input>
          <image-uploader
            ref="image-uploader"
            v-model="info.fileList"
            :max-count="5"
            @change="onImageChange"
          />
        </template>
      </van-field>
      <div class="details-page__footer-button-wrapper--fixed">
        <van-button block round type="info">提交</van-button>
      </div>
      <select-popup
        :choose-value="popupValue"
        :data="popupData"
        :title="popupTitle"
        :visible="popupVisible"
        :popup-style="{ height: '70vh' }"
        @close="onPopupClose"
        @confirm="onPopupConfirm"
      />
      <cascade-department
        v-model="info.department"
        :visible="departmentVisible"
        @close="departmentVisible = false"
        @confirm="onTreeConfirm"
      />
      <add-facility
        v-model="facilityMap"
        :visible="facilityVisible"
        activated="14"
        auth="org"
        title="隐患部位"
        @close="facilityVisible = false"
        @confirm="onFacilityConfirm"
      ></add-facility>
    </van-form>
  </div>
</template>

<script>
import { filterEmoji, operateMessage, parseTime } from "@/utils";
import { discoveryData } from "@/views/trouble/utils/constant";
import { loading } from "@/utils/constant";
import {
  getSelectPlans,
  getTroubleInfoById,
  commitTrouble
} from "@/api/psm/trouble";
import {
  getDepartmentByFacilityId,
  getSelectContractorsNames
} from "@/api/base";
import { mapState } from "vuex";
import getSelectMixin from "@/views/trouble/mixin/getSelectMixin";
import CascadeDepartment from "@/components/CascadeDepartment";
import SelectPopup from "@/components/SelectPopup";
import ImageUploader from "@/views/trouble/trouble-fix/components/ImageUploader";
import AddFacility from "@/components/AddFacility";

export default {
  components: {
    AddFacility,
    ImageUploader,
    SelectPopup,
    CascadeDepartment
  },
  mixins: [getSelectMixin],
  props: ["planId"],
  data() {
    return {
      showContractor: false,
      title: "隐患登记",
      popupVisible: false,
      facilityVisible: false,
      popupTitle: "",
      popupData: [],
      loading: { ...loading },
      info: {
        // 任务关联ID
        taskRecordId: "",
        file: [],
        fileList: [],
        name: "",
        orgCode: "",
        orgName: "",
        troubleLevel: "",
        // 隐患类别
        categoryId: "",
        categoryName: "",

        contractorId: "",
        contractorName: "",
        description: "",
        // 所属排查计划
        // planId: "",
        planName: "",
        // 所属部门
        department: "",
        departmentName: "",
        subDepartment: false, // cascade-department 组件没传 includeChildren 参数，默认false
        // 发现人部门，为登录用户
        discoverDeptId: "",
        // 发现人
        discoverer: "",
        // 是否已整改
        dispose: "0",
        // 隐患发现方式
        discoveryId: "1",
        discoverDate: "",
        // 检查依据
        gist: "",
        // 整改建议
        suggest: ""
      },
      discoveryData,
      planTypes: [],
      contractorTypes: [],
      popupType: "",
      popupTypeName: "",
      popupValue: "",
      departmentVisible: false,
      selectValue: "",
      popupTypesConf: {
        troubleLevel: {
          title: "隐患级别",
          keyName: "troubleLevelName",
          typesKey: "levelTypes"
        },
        categoryId: {
          title: "隐患类别",
          keyName: "categoryName",
          typesKey: "classesTypes"
        },
        contractorId: {
          title: "承包商",
          keyName: "contractorName",
          typesKey: "contractorTypes"
        }
        // planId: {
        //   title: "所属计划",
        //   keyName: "planName",
        //   typesKey: "planTypes"
        // }
      },
      facilityMap: {
        label: "",
        value: "",
        type: ""
      }
    };
  },
  activated() {
    this.initValue();
    this.initTypes();
    const { orgCode } = this.userInfo;
    this.info.orgCode = orgCode;
    // this.info.planId = this.planId;
    this.getSelectContractorsNames();
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  methods: {
    initValue() {
      Object.keys(this.info).forEach(key => {
        if (
          key !== "dispose" &&
          key !== "discoveryId" &&
          key !== "file" &&
          key !== "fileList" &&
          key !== "subDepartment"
        ) {
          this.info[key] = "";
        }
      });
      this.info.fileList = [];
      this.info.file = [];
      this.info.discoveryId = "1";
      this.facilityMap.label = "";
      this.facilityMap.value = "";
      this.facilityMap.type = "";
    },
    handleInput(key) {
      this.info[key] = filterEmoji(this.info[key]);
    },
    async onFacilityConfirm(facility) {
      console.log(facility, "facility");
      this.info.poiName = facility.label;
      this.info.poiType = facility.type;
      this.info.poiId = facility.value;
      if (!facility || !Object.keys(facility).length || !facility.value) {
        this.info.department = "";
        this.info.departmentName = "";
        return false;
      }
      try {
        const ret = await getDepartmentByFacilityId(facility.value);
        this.info.department = ret.department;
        this.info.departmentName = ret.departmentName;
      } catch (e) {
        console.log("onFacilityChange -> e", e);
      }
    },
    formatterGetInfo(result) {
      this.info.fileList =
        result.files &&
        result.files.map(file => {
          return {
            url: `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=false&fileName=${file.id}`,
            name: file.name,
            fileInfo: file,
            isImage: true
          };
        });
      this.$refs["image-uploader"].setInnerValue(this.info.fileList);
      this.facilityMap.label = this.info.poiName;
      this.facilityMap.value = this.info.poiId;
      this.facilityMap.type = this.info.poiType;
    },
    async getInfo() {
      try {
        const res = await getTroubleInfoById(this.id);
        console.log("getInfo -> res", res);
        this.info = { ...this.info, ...res };
        this.formatterGetInfo(res);
        // this.selectValue = this.info.department;
      } catch (e) {
        console.log("getInfo -> e", e);
      }
    },

    async getSelectContractorsNames() {
      try {
        const params = {
          orgCodeInList: this.info.orgCode
        };
        const data = await getSelectContractorsNames(params);
        console.log(data);
        this.contractorTypes = data;
      } catch (error) {
        console.log(error);
      }
    },
    async initTypes() {
      try {
        const { orgCode } = this.userInfo;
        this.planTypes = await getSelectPlans({ orgCode });
        console.log(this.planTypes);
        const { label } =
          this.planTypes.find(x => x.value === this.planId) || {};
        this.info.planName = label || "";
      } catch (error) {
        console.log("initTypes -> error", error);
      }
    },
    onImageChange(val) {
      this.info.fileList = val;
    },
    onTreeConfirm({ label }, includeInfo) {
      this.info.departmentName = label;
      this.info.subDepartment = includeInfo?.include;
    },
    handlerTypesClick(ev, key) {
      // 如果之前被选中了，那就直接传递过去
      this.popupValue = this.info[key];

      const popupTypesConf = this.popupTypesConf;
      this.popupVisible = true;
      this.popupTitle = popupTypesConf[key].title;

      this.popupData = [...this[popupTypesConf[key].typesKey]];
      this.popupType = key;
      this.popupTypeName = popupTypesConf[key].keyName;
    },
    onPopupConfirm(row) {
      this.info[this.popupType] = row.value;
      this.info[this.popupTypeName] = row.label;
      if (this.popupType === "categoryId") {
        if (row.contractorStatus === 1) {
          this.showContractor = true;
        } else {
          this.showContractor = false;
          // 清空关联的下级
          this.info.contractorId = "";
          this.info.contractorName = "";
        }
      }
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    processDefaultSaveInfo() {
      const { orgCode, id, department, mobile } = this.userInfo;
      console.log(this.info.fileList, "this.info.fileList");
      this.info.file =
        this.info.fileList &&
        this.info.fileList.map(item => item && item.fileInfo);
      delete this.info.fileList;
      this.info.taskRecordId = -1;
      this.info.itemRecordsId = -1;
      this.info.checkItemId = -1;
      this.info.orgCode = orgCode;
      // 发现相关
      this.info.discoverer = id;
      this.info.discoverDeptId = department;
      this.info.discovererMobile = mobile;
      this.info.discoverDate = parseTime(Date.now(), "{y}-{m}-{d} {h}:{i}:{s}");
      console.log("commitTroubleInfo -> this.info", this.info);
    },
    async onSubmit(values) {
      console.log("onSubmit -> values", values);
      this.loading.status = true;
      this.processDefaultSaveInfo();
      try {
        const r = await commitTrouble(this.info);
        operateMessage(r, "提交隐患");
        if (r) {
          this.$router.back();
        }
        this.loading.status = false;
      } catch (e) {
        console.log("onSubmit -> e", e);
        this.loading.status = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.create-trouble {
  & ::v-deep {
    .details-page__last-from-item {
      padding-bottom: 20px;
    }
  }
}
.van-form {
  height: calc(100vh - 58px - 72px);
  overflow: auto;
  .van-radio-group {
    display: flex;
  }
}
.van-button {
  color: #fff;
  font-size: 16px;
}
</style>
